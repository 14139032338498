import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <big>Hi there! I'm Alan!</big>
        <p>
          I'm too lazy to make an actual website, so here's whatever this is.
        </p>
        <a
          className="App-link"
          href="https://t.me/haeimalan"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Me
        </a>
      </header>
    </div>
  );
}

export default App;
